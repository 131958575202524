import NavFactory from "@/client/extensions/helpers/navFactory.js";

export default async ({ asyncOps, store }) => {
  let adminNavItemFactoryFunction, navItems;

  try {
    adminNavItemFactoryFunction =
      await require("@/client/assets/static/core/nav/adminSideNavFactory.js");
    navItems = await adminNavItemFactoryFunction.default({
      asyncOps,
      store,
    });
  } catch (e) {
    return {};
  }

  let nav = new NavFactory(navItems);

  let appItems = {
    blockedContact: {
      icon: "shield-x",
      action: {
        type: "routerNavigation",
        data: {
          to: {
            name: "admin-entity-entityType",
            params: {
              entityType: "blockedContact",
            },
          },
        },
      },
      label: "entity.blockedContact.namePlural",
    },
  };

  return nav
    .removeItems(["ecommerceTitle", "vendor", "brand", "product", "order"])
    .addItemAfter("user", 'blockedContact',appItems.blockedContact)
    .getItems();
};
